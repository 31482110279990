@font-face {
    font-family: "Rubik";
    font-weight: 100;
    src: local("?"),
    url("../../assets/fonts/Rubik/Rubik-Light.woff") format("woff"),
    url("../../assets/fonts/Rubik/Rubik-Light.ttf") format("truetype"),
    url("../../assets/fonts/Rubik/Rubik-Light.otf") format("otf");
    font-display: swap;
}
@font-face {
    font-family: "Rubik";
    font-weight: 100;
    font-style: italic;
    src: local("?"),
    url("../../assets/fonts/Rubik/Rubik-LightItalic.woff") format("woff"),
    url("../../assets/fonts/Rubik/Rubik-LightItalic.ttf") format("truetype"),
    url("../../assets/fonts/Rubik/Rubik-LightItalic.otf") format("otf");
    font-display: swap;
}


@font-face {
    font-family: "Rubik";
    font-weight: 200;
    src: local("?"),
    url("../../assets/fonts/Rubik/Rubik-Regular.woff") format("woff"),
    url("../../assets/fonts/Rubik/Rubik-Regular.ttf") format("truetype"),
    url("../../assets/fonts/Rubik/Rubik-Regular.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 200;
    font-style: italic;
    src: local("?"),
    url("../../assets/fonts/Rubik/Rubik-Italic.woff") format("woff"),
    url("../../assets/fonts/Rubik/Rubik-Italic.ttf") format("truetype"),
    url("../../assets/fonts/Rubik/Rubik-Italic.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 300;
    src: local("?"),
    url("../../assets/fonts/Rubik/Rubik-Medium.woff") format("woff"),
    url("../../assets/fonts/Rubik/Rubik-Medium.ttf") format("truetype"),
    url("../../assets/fonts/Rubik/Rubik-Medium.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 300;
    font-style: italic;
    src: local("?"),
    url("../../assets/fonts/Rubik/Rubik-MediumItalic.woff") format("woff"),
    url("../../assets/fonts/Rubik/Rubik-MediumItalic.ttf") format("truetype"),
    url("../../assets/fonts/Rubik/Rubik-MediumItalic.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 400;
    src: local("?"),
    url("../../assets/fonts/Rubik/Rubik-SemiBold.woff") format("woff"),
    url("../../assets/fonts/Rubik/Rubik-SemiBold.ttf") format("truetype"),
    url("../../assets/fonts/Rubik/Rubik-SemiBold.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 400;
    font-style: italic;
    src: local("?"),
    url("../../assets/fonts/Rubik/Rubik-SemiBoldItalic.woff") format("woff"),
    url("../../assets/fonts/Rubik/Rubik-SemiBoldItalic.ttf") format("truetype"),
    url("../../assets/fonts/Rubik/Rubik-SemiBoldItalic.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 500;
    src: local("?"),
    url("../../assets/fonts/Rubik/Rubik-Bold.woff") format("woff"),
    url("../../assets/fonts/Rubik/Rubik-Bold.ttf") format("truetype"),
    url("../../assets/fonts/Rubik/Rubik-Bold.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 600;
    src: local("?"),
    url("../../assets/fonts/Rubik/Rubik-ExtraBold.woff") format("woff"),
    url("../../assets/fonts/Rubik/Rubik-ExtraBold.woff2") format("woff2"),
    url("../../assets/fonts/Rubik/Rubik-ExtraBold.ttf") format("truetype"),
    url("../../assets/fonts/Rubik/Rubik-ExtraBold.otf") format("otf");
    font-display: swap;
}


@font-face {
    font-family: "Rubik";
    font-weight: 500;
    font-style: italic;
    src: local("?"),
    url("../../assets/fonts/Rubik/Rubik-BoldItalic.woff") format("woff"),
    url("../../assets/fonts/Rubik/Rubik-BoldItalic.ttf") format("truetype"),
    url("../../assets/fonts/Rubik/Rubik-BoldItalic.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Segoe UI";
    font-weight: 200;
    src: local("?"),
    url("../../assets/fonts/SegoeUI/SegoeUI.woff") format("woff"),
    url("../../assets/fonts/SegoeUI/SegoeUI.TTF") format("truetype"),
    url("../../assets/fonts/SegoeUI/SegoeUI.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Helvetica LT Std";
    font-weight: 100;
    src: local("?"),
    url("../../assets/fonts/Helvetica/HelveticaLTStd-Light.woff") format("woff"),
    url("../../assets/fonts/Helvetica/HelveticaLTStd-Light.woff2") format("woff2"),
    url("../../assets/fonts/Helvetica/HelveticaLTStd-Light.ttf") format("truetype"),
    url("../../assets/fonts/Helvetica/HelveticaLTStd-Light.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Helvetica LT Std";
    font-weight: 200;
    src: local("?"),
    url("../../assets/fonts/Helvetica/HelveticaLTStd-Roman.woff") format("woff"),
    url("../../assets/fonts/Helvetica/HelveticaLTStd-Roman.woff2") format("woff2"),
    url("../../assets/fonts/Helvetica/HelveticaLTStd-Roman.ttf") format("truetype"),
    url("../../assets/fonts/Helvetica/HelveticaLTStd-Roman.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Helvetica LT Std";
    font-weight: 500;
    src: local("?"),
    url("../../assets/fonts/Helvetica/HelveticaLTStd-Bold.woff") format("woff"),
    url("../../assets/fonts/Helvetica/HelveticaLTStd-Bold.woff2") format("woff2"),
    url("../../assets/fonts/Helvetica/HelveticaLTStd-Bold.ttf") format("truetype"),
    url("../../assets/fonts/Helvetica/HelveticaLTStd-Bold.otf") format("otf");
    font-display: swap;
}