@font-face {
    font-family: "Rubik";
    font-weight: 100;
    src: local("?"),
    url(/static/media/Rubik-Light.c8fd523b.woff) format("woff"),
    url(/static/media/Rubik-Light.7ee66bf7.ttf) format("truetype"),
    url(/static/media/Rubik-Light.cc302e56.otf) format("otf");
    font-display: swap;
}
@font-face {
    font-family: "Rubik";
    font-weight: 100;
    font-style: italic;
    src: local("?"),
    url(/static/media/Rubik-LightItalic.1b3aba66.woff) format("woff"),
    url(/static/media/Rubik-LightItalic.987cbfce.ttf) format("truetype"),
    url(/static/media/Rubik-LightItalic.d2cc31af.otf) format("otf");
    font-display: swap;
}


@font-face {
    font-family: "Rubik";
    font-weight: 200;
    src: local("?"),
    url(/static/media/Rubik-Regular.99039f20.woff) format("woff"),
    url(/static/media/Rubik-Regular.da39336c.ttf) format("truetype"),
    url(/static/media/Rubik-Regular.c0794caf.otf) format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 200;
    font-style: italic;
    src: local("?"),
    url(/static/media/Rubik-Italic.79874bcb.woff) format("woff"),
    url(/static/media/Rubik-Italic.dace38b0.ttf) format("truetype"),
    url(/static/media/Rubik-Italic.b3a372a3.otf) format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 300;
    src: local("?"),
    url(/static/media/Rubik-Medium.2863dba8.woff) format("woff"),
    url(/static/media/Rubik-Medium.5bd2b2c6.ttf) format("truetype"),
    url(/static/media/Rubik-Medium.4fb81536.otf) format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 300;
    font-style: italic;
    src: local("?"),
    url(/static/media/Rubik-MediumItalic.19172901.woff) format("woff"),
    url(/static/media/Rubik-MediumItalic.1bc75ab4.ttf) format("truetype"),
    url(/static/media/Rubik-MediumItalic.f0bc80d3.otf) format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 400;
    src: local("?"),
    url(/static/media/Rubik-SemiBold.818a856d.woff) format("woff"),
    url(/static/media/Rubik-SemiBold.52d2adf4.ttf) format("truetype"),
    url(/static/media/Rubik-SemiBold.ede4c0fb.otf) format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 400;
    font-style: italic;
    src: local("?"),
    url(/static/media/Rubik-SemiBoldItalic.b8472c3b.woff) format("woff"),
    url(/static/media/Rubik-SemiBoldItalic.57d31463.ttf) format("truetype"),
    url(/static/media/Rubik-SemiBoldItalic.8fa25312.otf) format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 500;
    src: local("?"),
    url(/static/media/Rubik-Bold.990bb8e5.woff) format("woff"),
    url(/static/media/Rubik-Bold.383b8c94.ttf) format("truetype"),
    url(/static/media/Rubik-Bold.08bf3f17.otf) format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    font-weight: 600;
    src: local("?"),
    url(/static/media/Rubik-ExtraBold.29af0e99.woff) format("woff"),
    url(/static/media/Rubik-ExtraBold.f97e0e1e.woff2) format("woff2"),
    url(/static/media/Rubik-ExtraBold.e3e0ab93.ttf) format("truetype"),
    url(/static/media/Rubik-ExtraBold.fba736aa.otf) format("otf");
    font-display: swap;
}


@font-face {
    font-family: "Rubik";
    font-weight: 500;
    font-style: italic;
    src: local("?"),
    url(/static/media/Rubik-BoldItalic.ceec07a9.woff) format("woff"),
    url(/static/media/Rubik-BoldItalic.fd2b84fe.ttf) format("truetype"),
    url(/static/media/Rubik-BoldItalic.781dcd60.otf) format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Segoe UI";
    font-weight: 200;
    src: local("?"),
    url(/static/media/SegoeUI.6f67030c.woff) format("woff"),
    url(/static/media/SegoeUI.90417751.TTF) format("truetype"),
    url(/static/media/SegoeUI.7a5a0643.otf) format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Helvetica LT Std";
    font-weight: 100;
    src: local("?"),
    url(/static/media/HelveticaLTStd-Light.43336ac7.woff) format("woff"),
    url(/static/media/HelveticaLTStd-Light.bed752c4.woff2) format("woff2"),
    url(/static/media/HelveticaLTStd-Light.fb5072d6.ttf) format("truetype"),
    url(/static/media/HelveticaLTStd-Light.14c4e0be.otf) format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Helvetica LT Std";
    font-weight: 200;
    src: local("?"),
    url(/static/media/HelveticaLTStd-Roman.00bd0752.woff) format("woff"),
    url(/static/media/HelveticaLTStd-Roman.f19e5f3f.woff2) format("woff2"),
    url(/static/media/HelveticaLTStd-Roman.1d276fb7.ttf) format("truetype"),
    url(/static/media/HelveticaLTStd-Roman.4ff7f98a.otf) format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Helvetica LT Std";
    font-weight: 500;
    src: local("?"),
    url(/static/media/HelveticaLTStd-Bold.4c633d96.woff) format("woff"),
    url(/static/media/HelveticaLTStd-Bold.c72ac736.woff2) format("woff2"),
    url(/static/media/HelveticaLTStd-Bold.6fadf5f0.ttf) format("truetype"),
    url(/static/media/HelveticaLTStd-Bold.4bc96e23.otf) format("otf");
    font-display: swap;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 100vw;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 714px) {
    .MuiToolbar-gutters {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
}

.MuiListItemIcon-root {
    min-width: 39px !important;
}

.MuiChip-outlined .MuiChip-iconSmall {
    margin-left: 5px !important;
}
